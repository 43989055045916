import {createGlobalStyle, css} from 'styled-components';

const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    white: 'white',
    black: 'black',
    green: '#79b959',
    red: '#ea3c5c',
    orange: '#fd7831',
    gray: '#a7a7a7',
    lightgray: 'lightgray',
    lightgrayOverlay: '#f5f5f5',
    blackOverlay: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.8) 100%)',
  },
  menuHeight: '80px',
  spacing: {
    big: '60px',
    large: '35px',
    default: '20px',
    small: '15px',
    xsmall: '8px',
  },
  fontSize: {
    xxl: '75px',
    big: '60px',
    large: '40px',
    medium: '30px',
    default: '20px',
    small: '15px',
  },
};

export const media = Object.keys(theme.breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${theme.breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const GlobalStyle = createGlobalStyle`

  @keyframes shake-animation {
    0% { transform:translate(0,0) }
    25% { transform:translate(0,4px) }
    50% { transform:translate(0,0) }
    75% { transform:translate(0,4px) }
    100% { transform:translate(0,0) }
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/static/webfonts/Roboto-Regular.woff2') format('woff2'),
        url('/static/webfonts/Roboto-Regular.woff') format('woff'),
        url('/static/webfonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/static/webfonts/Roboto-Bold.woff2') format('woff2'),
        url('/static/webfonts/Roboto-Bold.woff') format('woff'),
        url('/static/webfonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/static/webfonts/Roboto-Italic.woff2') format('woff2'),
        url('/static/webfonts/Roboto-Italic.woff') format('woff'),
        url('/static/webfonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  .fonts-loaded {
    * {
      font-family: 'Roboto';
    }
  };

  body {
    background:${({theme}) => theme.colors.white};
    margin:0;
    overflow-x:hidden;
    * {
      box-sizing: border-box;
      font-family: sans-serif;
      font-size: ${({theme}) => theme.fontSize.default};
    }
     a {
       color:${({theme}) => theme.colors.green};
       text-decoration: none;
       &:hover{
         text-decoration:underline;
       }
     }
  }`;

export default theme;
